import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  private loggedIn = false;

  constructor(private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      if(currentUser.userType == 200 || currentUser.userType == 250 ){
         // Admin logged in so return true
      console.log('true');
      return true;
      }
     
    }
    console.log('false');
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/dashboard'], { queryParams: { returnUrl: state.url } });
    return false;
  }
  public get isLoggedIn() {
    if (localStorage.getItem('currentUser')) {
      return true;
    } else {
      return false;
    }
  }
  
}
