import { DentGrid } from './work-order';
import { panelUI } from './estimate';
import { MasterData } from './master-data';

export class Calculation {
  partsCount: number;
  randiTotal: number;
  pdrTotal: number;
  partTotal: number;
  invoiceTotal: number;
  discountAmount: number;
  subtotalBeforeDiscount: number;
  subtotalBeforeTax: number;
  flatRatePdr: number;
  flatRateRI: number;
  taxRate1: number;
  taxRate2: number;
  taxRate3: number;
  taxAmount1: number;
  taxAmount2: number;
  taxAmount3: number;
  dentGridSelected: DentGrid;
  masterData: MasterData;

  constructor() {
    this.masterData = new MasterData();
    this.partsCount = 0;
    this.randiTotal = 0;
    this.pdrTotal = 0;
    this.partTotal = 0;
    this.discountAmount = 0;
    this.subtotalBeforeDiscount = 0;
    this.subtotalBeforeTax = 0;
    this.flatRatePdr = 0;
    this.flatRateRI = 0;
    this.taxAmount1 = 0;
    this.taxAmount2 = 0;
    this.taxAmount3 = 0;
    this.invoiceTotal = 0;
    this.loadSavedData();
  }

  loadSavedData() {}

  calculateTotalValues(
    panels: Array<panelUI>,
    panelBodyList,
    parts,
    totalSubletCharge,
    appraisedRI,
    flatRatePdr,
    flatRateRI,
    discount,
    customerTaxRegion,
    customerExempt
  ) {
    this.partsCount = 0;
    this.pdrTotal = 0;
    this.partTotal = 0;

    this.flatRatePdr = flatRatePdr ? +flatRatePdr : 0;
    this.flatRateRI = flatRateRI ? +flatRateRI : 0;

    if (flatRatePdr && flatRatePdr > 0) {
      this.pdrTotal = flatRatePdr;
      // for (let panel of panels) {
      //     if (panel.parts) {
      //         this.partsCount = this.partsCount + panel.parts.length;
      //     }

      // }
    } else {
      for (let panel of panels) {
        let bodyPanel = panelBodyList.find((i) => i.PanelId == panel.panelId);
        if (bodyPanel) {
          if (panel.flatRate) {
            this.pdrTotal = this.pdrTotal + panel.flatRate;
          } else if (panel.appraised) {
            this.pdrTotal = this.pdrTotal + panel.appraised;
          }
        }
      }
    }

    this.partsCount = this.partsCount + parts.length;
    for (let panel of panels) {
      let bodyPanel = panelBodyList.find((i) => i.PanelId == panel.panelId);
      if (bodyPanel) {
        if (panel.parts) {
          this.partsCount = this.partsCount + panel.parts.length;
          for (let part of panel.parts) {
            this.partTotal = this.partTotal + part.price;
          }
        }
      }
    }
    for (let part of parts) {
      this.partTotal = this.partTotal + part.price;
    }

    if (flatRateRI && flatRateRI > 0) {
      this.randiTotal = flatRateRI;
    } else {
      this.randiTotal = appraisedRI;
    }
    this.invoiceTotal = this.pdrTotal + this.randiTotal + this.partTotal;
    if (totalSubletCharge && totalSubletCharge > 0) {
      this.invoiceTotal = this.invoiceTotal + totalSubletCharge;
    }
    this.subtotalBeforeDiscount = this.invoiceTotal;
    if (discount && discount > 0) {
      this.discountAmount = (this.invoiceTotal * discount) / 100;
      this.invoiceTotal = this.invoiceTotal - this.discountAmount;
    }

    let Region = this.masterData.regions.find(
      (i) => i.Abbreviation === customerTaxRegion
    );

    if (Region) {
      this.taxRate1 = Region ? +Region.SalesTaxRate1 : 0.0;
      this.taxRate2 = Region ? +Region.SalesTaxRate2 : 0.0;
      this.taxRate3 = Region ? +Region.SalesTaxRate3 : 0.0;

      if (!customerExempt.PST) {
        this.taxAmount1 = this.invoiceTotal * this.taxRate1;
      } else {
        this.taxAmount1 = 0;
      }
      if (!customerExempt.GST) {
        this.taxAmount2 = this.invoiceTotal * this.taxRate2;
      } else {
        this.taxAmount2 = 0;
      }
      if (!customerExempt.HST) {
        this.taxAmount3 = this.invoiceTotal * this.taxRate3;
      } else {
        this.taxAmount3 = 0;
      }
      this.subtotalBeforeTax = this.invoiceTotal;

      this.invoiceTotal =
        this.subtotalBeforeTax +
        this.taxAmount1 +
        this.taxAmount2 +
        this.taxAmount3;
    }

    sessionStorage.setItem('partsCount', this.partsCount.toString());
    sessionStorage.setItem('randiTotal', this.randiTotal.toString());
    sessionStorage.setItem('pdrTotal', this.pdrTotal.toString());
    sessionStorage.setItem('invoiceTotal', this.invoiceTotal.toString());
  }

  loadCalculationData() {
    this.partsCount = +sessionStorage.getItem('partsCount');
    this.randiTotal = +sessionStorage.getItem('randiTotal');
    this.pdrTotal = +sessionStorage.getItem('pdrTotal');
    this.invoiceTotal = +sessionStorage.getItem('invoiceTotal');
  }

  //NEW

  calculateDentCoverageDccHail(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLadderWork,
    IsLargeVehicle,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };

    let baseCharge = 0;
    let charges = [];
    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }

      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }

    return Result;
  }

  calculateDentCoverageAllstate(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLadderWork,
    IsLargeVehicle,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    let baseCharge = 0;
    let charges = [];

    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }

    return Result;
  }

  calculateDentCoverageSgi(
    dentGridSelected,
    PanelId,
    DentCount,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };

    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    let MaterialId = IsAluminum ? 'aluminum' : 'steel';
    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.MaterialId === MaterialId
      );

    let StandardCoverage = PanelCoverage ? PanelCoverage.StandardCoverage : 0.0;
    let OversizeCoverage = PanelCoverage ? PanelCoverage.OversizeCoverage : 0.0;

    Result.DentCoverage = DentCount * StandardCoverage;

    // if (IsDoublePanel) Result.Upcharge = Result.DentCoverage * (this.DentGridRules.DoublePanelUpchargePercent / 100.0);

    if (OversizeCount > 0)
      Result.OversizeCoverage = OversizeCount * OversizeCoverage;

    return Result;
  }

  calculateDentCoverageTd(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;

    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    let baseCharge = 0;
    let charges = [];
    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    // if (IsLargeVehicle) {
    //     Result.Upcharge += Result.DentCoverage * (this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent / 100.0);
    // } else {

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }
    // if (PanelId == 2) {
    //     if (IsLadderWork) Result.Upcharge += (baseCharge + Result.Upcharge) * (this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent / 100.0);
    // }

    return Result;
  }

  calculateDentCoverageMpic(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    //if (OversizeCount > 0) Result.OversizeCoverage = this.dentGridSelected.DentGridRules.OversizeCoverage;
    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let MaterialId = IsAluminum ? 'aluminum' : 'steel';

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.MaterialId === MaterialId
      );

    let GraduatedCoverage =
      PanelCoverage &&
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) => item.DamageCategoryId === DamageCategoryId
      );

    Result.DentCoverage =
      GraduatedCoverage && GraduatedCoverage.Coverage
        ? GraduatedCoverage.Coverage
        : 0.0;

    if (IsAluminum && Result.Upcharge == 0.0)
      Result.Upcharge +=
        (Result.DentCoverage + Result.OversizeCoverage) *
        (this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent /
          100.0);
    //Result.OversizeCoverage = 0;

    
    return Result;
  }

  calculateDentCoverageCoOperators(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR,
    IsCorrosionProtect
  ) {
    this.dentGridSelected = dentGridSelected;

    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    let baseCharge = 0;
    let charges = [];
    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (IsCorrosionProtect) {
      Result.Upcharge =
        Result.Upcharge +
        this.dentGridSelected.DentGridRules.CorrosionProtection;
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }

      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    // if (IsLargeVehicle) {
    //     Result.Upcharge += Result.DentCoverage * (this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent / 100.0);
    // } else {

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }
    // if (PanelId == 2) {
    //     if (IsLadderWork) Result.Upcharge += (baseCharge + Result.Upcharge) * (this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent / 100.0);
    // }

    return Result;
  }

  calculateDentCoverageAllstateCanada(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    let baseCharge = 0;
    let charges = [];

    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }

    return Result;
  }

  calculateDentCoverageDGIG(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR,
    IsCorrosionProtect
  ) {
    this.dentGridSelected = dentGridSelected;

    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    let baseCharge = 0;
    let charges = [];
    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (IsCorrosionProtect) {
      Result.Upcharge =
        Result.Upcharge +
        this.dentGridSelected.DentGridRules.CorrosionProtection;
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }

      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    // if (IsLargeVehicle) {
    //     Result.Upcharge += Result.DentCoverage * (this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent / 100.0);
    // } else {

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }
    // if (PanelId == 2) {
    //     if (IsLadderWork) Result.Upcharge += (baseCharge + Result.Upcharge) * (this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent / 100.0);
    // }

    return Result;
  }

  calculateDentCoverageEconomical(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    let baseCharge = 0;
    let charges = [];

    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }

    return Result;
  }

  calculateDentCoverageIntact(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    let baseCharge = 0;
    let charges = [];

    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }

    return Result;
  }

  calculateDentCoverageSgiCanada(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    let baseCharge = 0;
    let charges = [];

    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }

    return Result;
  }

  calculateDentCoverageWawanesa(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    let baseCharge = 0;
    let charges = [];

    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }

    return Result;
  }

  calculateDentCoverageAmericanFamily(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR,
    IsCorrosion,
    IsGluePull
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    let baseCharge = 0;
    let charges = [];

    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsGluePull) {
      charges.push(this.dentGridSelected.DentGridRules.GluePullPercentage);
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (IsCorrosion) {
      Result.Upcharge =
        Result.Upcharge + this.dentGridSelected.DentGridRules.Corrosion;
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }

    return Result;
  }

  calculateDentCoverageLibertyMutual(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    let baseCharge = 0;
    let charges = [];

    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }

    return Result;
  }
  calculateDentCoverageFarmersInsu(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    let baseCharge = 0;
    let charges = [];

    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }

    return Result;
  }
  calculateDentCoveragePDRLinx(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    let baseCharge = 0;
    let charges = [];

    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }

    return Result;
  }
  calculateDentCoverageGeicoUSA(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR,
    IsCorrosionProtect
  ) {
    this.dentGridSelected = dentGridSelected;

    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    let baseCharge = 0;
    let charges = [];
    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (IsCorrosionProtect) {
      Result.Upcharge =
        Result.Upcharge +
        this.dentGridSelected.DentGridRules.CorrosionProtection;
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }

      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    // if (IsLargeVehicle) {
    //     Result.Upcharge += Result.DentCoverage * (this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent / 100.0);
    // } else {

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }
    // if (PanelId == 2) {
    //     if (IsLadderWork) Result.Upcharge += (baseCharge + Result.Upcharge) * (this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent / 100.0);
    // }

    return Result;
  }

  calculateDentCoverageProgressiveUSA(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR,
    IsCorrosionProtect,
    IsBodyLine,
    IsDeepDents
  ) {
    this.dentGridSelected = dentGridSelected;

    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    let baseCharge = 0;
    let charges = [];
    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsBodyLine) {
      charges.push(this.dentGridSelected.DentGridRules.BodyLinePercentage);
    }
    if (IsDeepDents) {
      charges.push(this.dentGridSelected.DentGridRules.DeepDentsPercentage);
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (IsCorrosionProtect) {
      Result.Upcharge =
        Result.Upcharge +
        this.dentGridSelected.DentGridRules.CorrosionProtection;
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }

      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    // if (IsLargeVehicle) {
    //     Result.Upcharge += Result.DentCoverage * (this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent / 100.0);
    // } else {

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }
    // if (PanelId == 2) {
    //     if (IsLadderWork) Result.Upcharge += (baseCharge + Result.Upcharge) * (this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent / 100.0);
    // }

    return Result;
  }

  calculateDentCoverageStateFarmUSA(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR,
    IsCorrosionProtect
  ) {
    this.dentGridSelected = dentGridSelected;

    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    let baseCharge = 0;
    let charges = [];
    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (IsCorrosionProtect) {
      Result.Upcharge =
        Result.Upcharge +
        this.dentGridSelected.DentGridRules.CorrosionProtection;
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }

      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    // if (IsLargeVehicle) {
    //     Result.Upcharge += Result.DentCoverage * (this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent / 100.0);
    // } else {

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }
    // if (PanelId == 2) {
    //     if (IsLadderWork) Result.Upcharge += (baseCharge + Result.Upcharge) * (this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent / 100.0);
    // }

    return Result;
  }

  calculateDentCoverageStormArtUSA(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR,
    IsCorrosionProtect
  ) {
    this.dentGridSelected = dentGridSelected;

    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    let baseCharge = 0;
    let charges = [];
    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (IsCorrosionProtect) {
      Result.Upcharge =
        Result.Upcharge +
        this.dentGridSelected.DentGridRules.CorrosionProtection;
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }

      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    // if (IsLargeVehicle) {
    //     Result.Upcharge += Result.DentCoverage * (this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent / 100.0);
    // } else {

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }
    // if (PanelId == 2) {
    //     if (IsLadderWork) Result.Upcharge += (baseCharge + Result.Upcharge) * (this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent / 100.0);
    // }

    return Result;
  }
  calculateDentCoverageSolutionWorks(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork,
    IsConventionalRepair,
    IsMaxPDR,
    IsCorrosionProtect
  ) {
    this.dentGridSelected = dentGridSelected;

    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };
    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }

    let baseCharge = 0;
    let charges = [];
    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }
    if (IsLadderWork) {
      if (PanelId == 2) {
        charges.push(
          this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
        );
      }
    }

    if (IsCorrosionProtect) {
      Result.Upcharge =
        Result.Upcharge +
        this.dentGridSelected.DentGridRules.CorrosionProtection;
    }

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }

      return Result;
    }

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) {
      baseCharge = Result.DentCoverage + Result.OversizeCoverage;
      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += baseCharge * (charge / 100.0);
      }
      return Result;
    }

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    // if (IsLargeVehicle) {
    //     Result.Upcharge += Result.DentCoverage * (this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent / 100.0);
    // } else {

    baseCharge = Result.DentCoverage + Result.OversizeCoverage;
    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += baseCharge * (charge / 100.0);
    }
    // if (PanelId == 2) {
    //     if (IsLadderWork) Result.Upcharge += (baseCharge + Result.Upcharge) * (this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent / 100.0);
    // }

    return Result;
  }

  calculateDentCoverageSgiMatrix(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsConventionalRepair,
    IsMaxPDR
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };

    if (IsConventionalRepair || IsMaxPDR) {
      return Result;
    }
    let charges = [];
    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }


    // let MaterialId = IsAluminum ? 'aluminum' : 'steel';

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );

    let GraduatedCoverage =
      PanelCoverage &&
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) => item.DamageCategoryId === DamageCategoryId
      );

    Result.DentCoverage =
      GraduatedCoverage && GraduatedCoverage.Coverage
        ? GraduatedCoverage.Coverage
        : 0.0;

    // let StandardCoverage = PanelCoverage ? PanelCoverage.StandardCoverage : 0.0;
    // let OversizeCoverage = PanelCoverage ? PanelCoverage.OversizeCoverage : 0.0;

    // // if (IsDoublePanel) Result.Upcharge = Result.DentCoverage * (this.DentGridRules.DoublePanelUpchargePercent / 100.0);

    if (OversizeCount > 0)
    Result.OversizeCoverage =
      OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;


    if (IsAluminum && Result.Upcharge == 0.0)
    Result.Upcharge +=
      (Result.DentCoverage + Result.OversizeCoverage) *
      (this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent /
        100.0);
    return Result;
  }

  // OLD

  calculateDentCoverageDccHailOld(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLadderWork,
    IsLargeVehicle
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) return Result;

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) return Result;

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    let charges = [];
    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    }
    if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    }
    if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    }
    if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    }

    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      Result.Upcharge += Result.DentCoverage * (charge / 100.0);
    }
    if (PanelId == 2) {
      if (IsLadderWork)
        Result.Upcharge +=
          Result.DentCoverage *
          (this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent /
            100.0);
    }
    return Result;
  }

  calculateDentCoverageAllstateOld(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLadderWork,
    IsLargeVehicle
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) return Result;

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) return Result;

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    let charges = [];
    if (IsAluminum) {
      charges.push(
        this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
      );
    } else if (IsDoublePanel) {
      charges.push(
        this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
      );
    } else if (IsHighStrengthSteel) {
      charges.push(
        this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
      );
    } else if (IsLargeVehicle) {
      charges.push(
        this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent
      );
    } else if (IsLadderWork) {
      charges.push(
        this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent
      );
    }

    if (charges.length > 0) {
      let charge = Math.max.apply(null, charges);
      if (charge > 25) charge = 25;
      Result.Upcharge += Result.DentCoverage * (charge / 100.0);
    }
    return Result;
  }

  calculateDentCoverageSgiOld(
    dentGridSelected,
    PanelId,
    DentCount,
    OversizeCount,
    IsAluminum,
    IsDoublePanel
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };

    let MaterialId = IsAluminum ? 'aluminum' : 'steel';
    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.MaterialId === MaterialId
      );

    let StandardCoverage = PanelCoverage ? PanelCoverage.StandardCoverage : 0.0;
    let OversizeCoverage = PanelCoverage ? PanelCoverage.OversizeCoverage : 0.0;

    Result.DentCoverage = DentCount * StandardCoverage;

    // if (IsDoublePanel) Result.Upcharge = Result.DentCoverage * (this.DentGridRules.DoublePanelUpchargePercent / 100.0);

    if (OversizeCount > 0)
      Result.OversizeCoverage = OversizeCount * OversizeCoverage;

    return Result;
  }

  calculateDentCoverageTdOld(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    SizeCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel,
    IsHighStrengthSteel,
    IsLargeVehicle,
    IsLadderWork
  ) {
    this.dentGridSelected = dentGridSelected;

    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };

    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.PanelId === PanelId
      );
    if (!PanelCoverage) return Result;

    let GraduatedCoverage =
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) =>
          item.DamageCategoryId === DamageCategoryId &&
          item.SizeCategoryId === SizeCategoryId
      );
    if (!GraduatedCoverage) return Result;

    Result.DentCoverage = GraduatedCoverage.Coverage
      ? GraduatedCoverage.Coverage
      : 0.0;

    if (IsLargeVehicle) {
      Result.Upcharge +=
        Result.DentCoverage *
        (this.dentGridSelected.DentGridRules.LargeVehicleUpchargePercent /
          100.0);
    } else {
      let charges = [];
      if (IsAluminum) {
        charges.push(
          this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent
        );
      }
      if (IsDoublePanel) {
        charges.push(
          this.dentGridSelected.DentGridRules.DoublePanelUpchargePercent
        );
      }
      if (IsHighStrengthSteel) {
        charges.push(
          this.dentGridSelected.DentGridRules.HighStrengthSteelUpchargePercent
        );
      }

      if (charges.length > 0) {
        let charge = Math.max.apply(null, charges);
        Result.Upcharge += Result.DentCoverage * (charge / 100.0);
      }
      if (PanelId == 2) {
        if (IsLadderWork)
          Result.Upcharge +=
            Result.DentCoverage *
            (this.dentGridSelected.DentGridRules.LadderWorkUpchargePercent /
              100.0);
      }
    }

    return Result;
  }

  calculateDentCoverageMpicOld(
    dentGridSelected,
    PanelId,
    DamageCategoryId,
    OversizeCount,
    IsAluminum,
    IsDoublePanel
  ) {
    this.dentGridSelected = dentGridSelected;
    let Result = { DentCoverage: 0.0, OversizeCoverage: 0.0, Upcharge: 0.0 };

    //if (OversizeCount > 0) Result.OversizeCoverage = this.dentGridSelected.DentGridRules.OversizeCoverage;
    if (OversizeCount > 0)
      Result.OversizeCoverage =
        OversizeCount * this.dentGridSelected.DentGridRules.OversizeCoverage;

    let MaterialId = IsAluminum ? 'aluminum' : 'steel';

    let PanelCoverage =
      this.dentGridSelected.PanelCoverage &&
      this.dentGridSelected.PanelCoverage.find(
        (item) => item.MaterialId === MaterialId
      );

    let GraduatedCoverage =
      PanelCoverage &&
      PanelCoverage.GraduatedCoverage &&
      PanelCoverage.GraduatedCoverage.find(
        (item) => item.DamageCategoryId === DamageCategoryId
      );

    Result.DentCoverage =
      GraduatedCoverage && GraduatedCoverage.Coverage
        ? GraduatedCoverage.Coverage
        : 0.0;

    if (IsAluminum && Result.Upcharge == 0.0)
      Result.Upcharge +=
        Result.DentCoverage *
        (this.dentGridSelected.DentGridRules.AluminumPanelUpchargePercent /
          100.0);

    return Result;
  }


}
