import { MasterData } from './master-data';

export class Profile {
  userTypes: Array<UserType>;
  userTypeList: Array<UserType>;
  userId: string;
  firstName: string;
  lastName: string;
  userType: number;
  email: string;
  phoneNo: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  postalCode: string;
  settings: SettingIn;
  masterData: MasterData;
  newPassword: string;
  image: File;
  affliate: number;
  customerDefaults: any;
  public imagePath;
  imgURL: any;
  public message: string;
  tax: TaxIn;
  company: string;
  provinces: Array<ProvinceIn>;
  provinceList: Array<ProvinceIn>;
  accounting: AccountingType;

  constructor() {
    this.userId = null;
    this.firstName = null;
    this.lastName = null;
    this.userType = null;
    this.email = null;
    this.phoneNo = null;
    this.address1 = '';
    this.address2 = '';
    this.city = '';
    this.province = '';
    this.postalCode = '';
    this.userTypes = [];
    this.provinces = [];
    this.company = '';
    // this.loadProjects();
    this.imgURL = 'assets/img/profile.png';
    this.settings = {
      reportingManager: null,
      commissionRate: null,
      hourlyRate: 0,
    };
    this.masterData = new MasterData();
    this.userTypes = this.masterData.roles;
    this.provinces = this.masterData.regions;
    this.userTypeList = [];
    this.provinceList = [];
    this.newPassword = null;
    this.affliate = null;
    this.customerDefaults = {
      discount: null,
      pst: true,
      gst: true,
      hst: true,
      defaultDentMetrix: null,
      updateRequired: 2,
    };
    this.tax = {
      isGSTAvailable: false,
      isHSTAvailable: false,
      gstNumber: 0,
    };
    this.accounting = {
      accountingType: NaN,
    };
  }

  viewImage() {}

  preview(files) {
    if (files.length === 0) return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  loadUserTypes() {
    this.userTypeList = [];

    for (let i = 0; i < this.userTypes.length; i++) {
      if (this.userTypes[i].RoleId == this.userType) {
        this.userTypeList.push(this.userTypes[i]);
      }
    }
  }
}

export interface SettingIn {
  reportingManager: string;
  commissionRate: number;
  hourlyRate: number;
}

export interface UserType {
  RoleId: number;
  RoleName: string;
}

export interface TaxIn {
  isGSTAvailable: boolean;
  isHSTAvailable: boolean;
  gstNumber: number;
}

export interface ProvinceIn {
  RegionId: number;
  Abbreviation: string;
  NameInEnglish: string;
}

export interface AccountingType {
  accountingType: number;
}
