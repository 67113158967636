import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpErrorResponse } from '@angular/common/http';
import { MasterData } from 'src/app/_models';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(private http: HttpClient) { }


  getMasterData(){
    console.log("master");
    return this.http.get("./../../assets/data/staticRef.json").subscribe(
      data => {
       // data = data as string [];	 // FILL THE ARRAY WITH DATA.
        //  console.log(this.arrBirds[1]);
        console.log(data);
        localStorage.setItem('masterData',JSON.stringify(data));
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let userType = currentUser.userType;
        if(userType){
          let masterData = new MasterData();
          let userTypeObj = masterData.roles.find(i => i.RoleId == userType);
          if(userTypeObj){
            localStorage.setItem('roleName', userTypeObj.RoleName);
          }
        }

        return data;
      },
      (err: HttpErrorResponse) => {
        console.log (err.message);
      }
    );
    
  }
}
