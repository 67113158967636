import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ErrorInterceptor } from 'src/app/interceptors/error';
import { Camera } from '@ionic-native/camera/ngx';
import { IonicSelectableModule } from 'ionic-selectable';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomHeader } from './interceptors/custom-header';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { ChartsModule } from 'ng2-charts';
import { RouterService } from './_services/data/router.service';

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        IonicSelectableModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        MaterialModule,
        ChartsModule,
    ],
    providers: [
        BarcodeScanner,
        StatusBar,
        SplashScreen,
        ScreenOrientation,
        RouterService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CustomHeader, multi: true },
        Camera,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
